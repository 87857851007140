
/* 3R */

.trions-develop__middle {
    min-height: 670px;
    position: relative;
}

.trions-develop__middle-circle {
  position: relative;
  box-sizing: border-box
}

.trions-develop__middle-circle-top-item {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: 'Playfair Display', serif;
  font-size: 150px;
  text-transform: uppercase;
  background: #fff;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  text-align: center;
  color: #54d0ae;
  z-index: 1
}

.trions-develop__middle-circle-top-item span {
  position: absolute
}

.trions-develop__middle-circle-top-item span.number {
  left: 50%;
  transform: translate(-100%, 0);
  top: -8%
}

.trions-develop__middle-circle-top-item span.text {
    left: 50%;
    transform: translate(-27%, -4px);
    top: 21%
}

.trions-develop__middle-circle-bottom-item {
    position: absolute;
    font-family: 'Playfair Display', serif;
    background: #fff;
    width: 230px;
    height: 230px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
}



.trions-develop__middle-circle-bottom-item::after {
  content: '';
  display: block;
  width: 95%;
  border-bottom: 5px dotted #23856a;
  position: absolute
}


.trions-develop__middle-circle-bottom-item h4 {
  font-size: 2rem;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 3%;
  color: #52d0ae;
}



.trions-develop__middle-circle-bottom-item p {
  font-family: 'Roboto', sans-serif;
  color: #6b6b6b;
  font-size: 1.185rem;
  line-height: 27px
}



.trions-develop__middle-circle-bottom-item--respecter {
  top: 11%;
  left: 0
}



.trions-develop__middle-circle-bottom-item--respecter::after {
  top: 42%;
  left: 100%;
  transform: rotate(-8deg)
}



.trions-develop__middle-circle-bottom-item--reduire {
  left: 27.4%;
  bottom: 80px;
}



.trions-develop__middle-circle-bottom-item--reduire::after {
  top: -42%;
  left: 37%;
  transform: rotate(-70deg)
}



.trions-develop__middle-circle-bottom-item--remplacer {
    top: 32.7%;
    right: 1.8%;
}



.trions-develop__middle-circle-bottom-item--remplacer::after {
  right: 81%;
  top: -10%;
  transform: rotate(40deg)
}



.trions-develop__middle-text {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding: 5.5% 0 1.5%
}

.trions-develop__middle-text span {
  font-weight: 700
}

@media only screen and (max-width: 1024px) {

    .trions-develop__middle-circle-top-item {
        width: 210px;
        height: 210px;
    }

    .trions-develop__middle-circle-top-item {
        font-size: 110px;
    }

    .trions-develop__middle-circle-bottom-item {
        width: 160px;
        height: 160px;
    }

    .trions-develop__middle-circle-bottom-item h4 {
        font-size: 1.4rem;
    }

    .trions-develop__middle-circle-bottom-item p {
        font-size: 0.7rem;
        line-height: 1;
    }

    .trions-develop__middle-circle-bottom-item--respecter {
        top: 11%;
        left: 6px;
    }

    .trions-develop__middle-circle-bottom-item--remplacer {
        top: 30%;
        right: 2%;
    }

    .trions-develop__middle {
        min-height: 520px;
        position: relative;
        padding-left: 4rem;
        padding-right: 4rem;
    }
}


@media only screen and (max-width: 768px) {
    .trions-develop__middle {
        padding-bottom: 2rem;
    }
    .trions-develop__middle-circle-top-item {
        position: relative;
        transform: none;
        margin: 0 auto 15px;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
    }

    .trions-develop__middle-circle-bottom-item {
        position: static;
        margin: 0 auto 15px;

    }

    .trions-develop__middle-circle-bottom-item::after {
        display: none;
    }
}
